import {
  RecipeComponentDetails,
  RecipeDescriptionDetails,
  RecipeFieldDetails,
  RecipeInfoDetails,
  RecipeProductDetails,
  RecipeSectionDetails,
  RecipeServiceDetails,
} from 'profilpol-types';
import { modal, notifications } from '.';
import ApiService from '../services/api-service';

export const getRecipe = (id: string) => async (dispatch: any) => {
  await ApiService.get(
    dispatch,
    'GET_RECIPE_SUCCESS',
    'GET_RECIPE_FAILED',
    null,
    null,
    `recipe/${id}`,
    'LOADING_RECIPE'
  );
};

export const deleteRecipeField = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_FIELD_SUCCESS',
    'DELETE_RECIPE_FIELD_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('recipe.field_deleted'));
    },
    null,
    `recipe/field/${id}`,
    'LOADING_RECIPE'
  );
};

export const addNewField = (field: RecipeFieldDetails) => ({
  type: 'ADD_NEW_RECIPE_FIELD',
  payload: field,
});

export const updateRecipeFied = (field: RecipeFieldDetails) => ({
  type: 'UPDATE_RECIPE_FIELD',
  payload: field,
});

export const deleteRecipeInfo = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_INFO_SUCCESS',
    'DELETE_RECIPE_INFO_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('recipe.info_deleted'));
    },
    null,
    `recipe/info/${id}`,
    'LOADING_RECIPE'
  );
};

export const addNewnfo = (info: RecipeInfoDetails) => ({
  type: 'ADD_NEW_RECIPE_INFO',
  payload: info,
});

export const updateRecipeIfno = (info: RecipeInfoDetails) => ({
  type: 'UPDATE_RECIPE_INFO',
  payload: info,
});

export const deleteRecipeSection = (id: string) => async (dispatch: any) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_SECTION_SUCCESS',
    'DELETE_RECIPE_SECTION_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('recipe.section_deleted'));
    },
    null,
    `recipe/section/${id}`,
    'LOADING_RECIPE'
  );
};

export const addNewSection = (section: RecipeSectionDetails) => ({
  type: 'ADD_NEW_RECIPE_SECTION',
  payload: section,
});

export const updateRecipeSection = (section: RecipeSectionDetails) => ({
  type: 'UPDATE_RECIPE_SECTION',
  payload: section,
});

export const addNewComponent = (component: RecipeComponentDetails) => ({
  type: 'ADD_NEW_RECIPE_COMPONENT',
  payload: component,
});

export const updateRecipeComponent = (component: RecipeComponentDetails) => ({
  type: 'UPDATE_RECIPE_COMPONENT',
  payload: component,
});

export const addNewProduct = (product: RecipeProductDetails) => ({
  type: 'ADD_NEW_RECIPE_PRODUCT',
  payload: product,
});

export const addNewService = (service: RecipeServiceDetails) => ({
  type: 'ADD_NEW_RECIPE_SERVICE',
  payload: service,
});

export const deleteRecipeComponent = (id: string, recipeId: string) => async (
  dispatch: any
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_COMPONENT_SUCCESS',
    'DELETE_RECIPE_COMPONENT_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(notifications.successNotification('recipe.component_deleted'));
      dispatch(getRecipe(recipeId));
    },

    null,
    `recipe/component/${id}`,
    'LOADING_RECIPE'
  );
};

export const deleteRecipeProduct = (id: string, recipeId: string) => async (
  dispatch: any
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_PRODUCT_SUCCESS',
    'DELETE_RECIPE_PRODUCT_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(
        notifications.successNotification('recipe.component_product_deleted')
      );
      dispatch(getRecipe(recipeId));
    },
    null,
    `recipe/product/${id}`,
    'LOADING_RECIPE'
  );
};

export const deleteRecipeService = (id: string, recipeId: string) => async (
  dispatch: any
) => {
  await ApiService.delete(
    dispatch,
    'DELETE_RECIPE_SERVICE_SUCCESS',
    'DELETE_RECIPE_SERVICE_FAILED',
    () => {
      dispatch(modal.hideModal());
      dispatch(
        notifications.successNotification('recipe.component_service_deleted')
      );
      dispatch(getRecipe(recipeId));
    },
    null,
    `recipe/service/${id}`,
    'LOADING_RECIPE'
  );
};

export const addNewRecipeDescription = (
  component: RecipeDescriptionDetails
) => ({
  type: 'ADD_NEW_RECIPE_DESCRIPTION',
  payload: component,
});

export const updateRecipeDescription = (
  component: RecipeDescriptionDetails
) => ({
  type: 'UPDATE_RECIPE_DESCRIPTION',
  payload: component,
});

export const deleteRecipeDescription = (id: string, recipeId: string) => ({
  type: 'DELETE_RECIPE_DESCRIPTION',
  payload: id,
});
