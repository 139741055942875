import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { settings, user } from '../../../actions';
import { ApplicationState } from '../../../reducers';
import { Spinner } from '../../Common';

import './AppWrapper.scss';

interface Props {
  children: React.ReactNode;
  full?: boolean;
}

const AppWrapper = ({ children, full }: Props) => {
  const { details: userDetails, loading } = useSelector((state: ApplicationState) => state.user);
  const loadingSettings = useSelector(
    (state: ApplicationState) => state.settings.loading
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(user.getUserDetails());
  }, []);

  useEffect(() => {
    if (userDetails) {
      dispatch(settings.getSettings());
    }
  }, [userDetails])
  
  if (loading || loadingSettings) return <Spinner overlay />; //we need to wait for user data and global settings to load
  return <div className={clsx('app-wrapper', { full: full })}>{children}</div>;
};

export default AppWrapper;
