import React from 'react';
import ApiService from '../../../services/api-service';
import './OfferClientDescription.scss';

interface Props {
  description: string;
}

const OfferClientDescription = ({ description }: Props) => {
  if (!description) return null;

  const splittedDescription = description
    ? description.replace(/["']/g, '').split('**')
    : [''];

  const clearedDescription = splittedDescription.map((el) => {
    let newVal = el.trim();

    newVal = el[0] === '.' ? el.substring(1) : el;

    return newVal.trim();
  });

  return (
    <>
      <ul className="offer-client-description">
      {[...clearedDescription].map((description) => {
        return description
          .split('\n')
          .filter((description) => description)
          .map((part: string) => {
            return (
              <li key={part}>
                {part.includes('.jpeg') ? (
                  <div className="offer-description-image">
                    <a
                      href={`${ApiService.url}recipe/description/image-file/${part}`}
                      target="_blank"
                    >
                      <img
                        src={`${ApiService.url}recipe/description/image-file/${part}`}
                      />
                    </a>
                  </div>
                ) : (
                  <p>{part}</p>
                )}
              </li>
            );
          });
      })}
      </ul>
    </>
  );
};

export default OfferClientDescription;
