import React from 'react';
import { ButtonsContainer, FormContainer } from '../../components/Layout';
import { Button, FieldsMapper, Spinner } from '../../components/Common';
import ModalContent from '../../components/Common/Modal/ModalContent';
import ModalTitle from '../../components/Common/Modal/ModalTitle';
import ModalHeader from '../../components/Common/Modal/ModalHeader';
import { __ } from '../../services/translation';
import { modal, recipes } from '../../actions';
import { useDispatch } from 'react-redux';
import { ItfApiForm } from 'itf_formbuilder_react';
import { RecipeProductDetails } from 'profilpol-types';
import { successNotification } from '../../actions/notifications';
import { ButtonType } from '../../components/Common/Button';
import ApiService from '../../services/api-service';
import { pathToObject } from '../../utils/path-to-object';
import { formOnTranslateString } from '../../utils/trans-form';

interface Props {
  componentId: string;
  recipeId: string;
  id?: string;
}

const RecipeProduct = ({ recipeId, componentId, id }: Props) => {
  const dispatch = useDispatch();
  let itfForm: any;

  const setRef = (form: any) => {
    itfForm = form;
  };
  return (
    <ModalContent>
      <ModalHeader>
        <ModalTitle title={id ? 'recipe.edit_product' : 'recipe.new_product'} />
      </ModalHeader>
      <FormContainer>
        <ItfApiForm
          ref={setRef}
          formId="recipeProductForm"
          schemaMayBeDynamic={true}
          schemaCacheEnabled={false}
          loadingInfoAboveContent
          loadingInfo={<Spinner overlay halfTransparent />}
          sendDelay={200}
          requiredIndicator="*"
          schemaFetcher={() =>
            ApiService.loadForm(
              id
                ? `recipe/form/update-product/${recipeId}/${componentId}/${id}`
                : `recipe/form/add-product/${recipeId}/${componentId}`
            )
          }
          sendFetcher={(formId: string, values: any) => {
            const { product, ...toSave } = values;
            const parsed = pathToObject(toSave);
            parsed.componentId = componentId;
            parsed.recipeId = recipeId;
            parsed.productId = product ? product.value || product : null;

            return ApiService.sendForm(
              id ? `recipe/product/${id}` : 'recipe/product',
              parsed,
              id ? 'PATCH' : 'POST',
              null
            );
          }}
          submitButton={(loading: boolean) => (
            <ButtonsContainer>
              <Button
                disabled={loading}
                small
                type={ButtonType.Button}
                danger
                click={() => dispatch(modal.hideModal())}
                text={'application.cancel'}
              />
              <Button
                disabled={loading}
                small
                type={ButtonType.Submit}
                success
                text={'application.save'}
              />
            </ButtonsContainer>
          )}
          onRenderFullField={(
            field,
            errors,
            fieldHtmlId,
            currentValue,
            onChange,
            onFocus,
            onBlur
          ) =>
            FieldsMapper(
              field,
              errors,
              fieldHtmlId,
              currentValue,
              onChange,
              onFocus,
              onBlur,
              { recipeId }
            )
          }
          onSavedSuccessfully={(data: RecipeProductDetails) => {
            dispatch(
              successNotification(
                id
                  ? 'recipe.component_product_updated'
                  : 'recipe.component_product_created'
              )
            );
            if (!id) {
              dispatch(recipes.addNewProduct(data));
            }
            dispatch(recipes.getRecipe(recipeId));
            dispatch(modal.hideModal());
          }}
          onTranslateString={formOnTranslateString}
        />
      </FormContainer>
    </ModalContent>
  );
};

export default RecipeProduct;
