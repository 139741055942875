import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Languages } from 'profilpol-types';
import { offer } from '../../../actions';
import CONFIG from '../../../config/app';
import { RootState } from '../../../reducers';
import './LanguageSelector.scss';

const LanguageSelector = () => {
  const dispatch = useDispatch();

  const { language } = useSelector((state: RootState) => state.offer);

  const setLanguage = (language: Languages) => {
    dispatch(offer.setOfferLanguage(language));
  };
  return (
    <div className="language-selector">
      {CONFIG.LANGUAGES.map((lang) => (
        <div className="language-flag-container">
          <button
            className={`language-flag ${lang === language ? 'active' : ''}`}
            onClick={() => setLanguage(lang)}
          >
            <img src={`/assets/${lang}.svg`} alt={lang} />
          </button>
        </div>
      ))}
    </div>
  );
};

export { LanguageSelector };
