import { Languages } from 'profilpol-types';

export default {
  LANGUAGES: ['pl', 'en', 'de'] as Languages[],
  DEFAULT_LANG: 'pl',
  DATE_FORMAT: 'dd-MM-yyyy',
  DATETIME_FORMAT: 'dd-MM-yyyy HH:mm',
  FULL_DATE_FORMAT: 'EEEE, dd MMMM yyyy',
  FLATPICKR_FORMAT: 'd-m-Y H:i',
  TIME_FORMAT: 'HH:mm',
  DEFAULT_MARGIN: 35,
};
