import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { SettingType } from 'profilpol-types';
import { __ } from '../../../services/translation';
import { ApplicationState } from '../../../reducers';
import './EurRatio.scss';

interface Props {
    // if current ratio is not fetched from settings
    ratio?: number;
    inside?: boolean;
}

const EurRatio = ({ ratio, inside }: Props) => {
    const settings = useSelector((state: ApplicationState) => state.settings.data);
    
    const eurRatio = settings.find(
        (setting) => setting.type === SettingType.EUR_PLN_RATIO
    );

    const ratioToDisplay = ratio || eurRatio?.value || 0;

    return <div className={clsx('ratio-container', { inside })} >
        <span className="ratio-label">
            {__('setting.eur_pln_ratio')}
        </span>
        <span className="ratio-value">
            {ratioToDisplay.toFixed(2)}
        </span>
    </div >
}

export { EurRatio }